<style scoped>
.rightControl{
    position: absolute;
    right: 0px;
    top: 0px;
}
</style>

<template>
  <div>
      <Row class="text-center" :gutter="8">
          <i-col span="24">
              <div v-if="svgInfo.svg" v-html="svgInfo.svg" class="svg-station-content " :style="{'height': clientHeight+'px'}" id="svg-station-content"></div>

              <div v-else class="default-content">
                <p style="font-size: xx-large;">抱歉，暂无SVG站点地图</p>
              </div>
          </i-col>
      </Row>

      <div class="rightControl">
        <div v-show="showRightBlock" class="right-block">
            <div class="right-icon-show" @click="showRightBlock=false">
            <Icon type="md-arrow-dropright" :size="20"/>
            </div>
            <div class="box_content p-t-10 p-l-5 p-r-5">
              <p class="title p-t-10 p-b-5">显示分级</p>
              <Row>
                <i-col span="24">
                  <RadioGroup v-model="chooseLevel" size="small" type="button" @on-change="handleChangeLevel">
                      <Radio v-for="level in levels" :label="level.id" :key="level.id">{{level.name}}</Radio>
                  </RadioGroup>
                </i-col>
              </Row>

              <p class="title p-t-10 p-b-5">显示元素</p>
              <Collapse v-model="chooseCategory" accordion simple>
                  <Panel :name="item" v-for="(item,i) in category" :key="i">
                      {{formatCategoryName(item)}}
                      <p slot="content">
                        <CheckboxGroup  v-model="chkAllElement" @on-change="chkAllElementChange">
                          <Row>
                            <i-col class="p-b-5" span="12" v-for="tag in svgTags.filter(x=>x.category === item)" :key="tag.id" >
                              <Checkbox :label="tag.name">
                                {{tag.title}}
                              </Checkbox>
                            </i-col>
                          </Row>
                        </CheckboxGroup>
                      </p>
                  </Panel>
              </Collapse>

              <p class="title p-t-10 p-b-5">操作</p>
              <p>
                <Button size="small" type="success" style="width: 80px" @click="modalLevelManage=true">管理分级</Button>
                <Button size="small" type="success" style="width: 80px" class="m-l-5" @click="handleSveLevelElement">保存设置</Button>
              </p>
            </div>
        </div>
        <div v-show="!showRightBlock" class="right-icon-hide" @click="showRightBlock=true">
            <Icon type="md-arrow-dropleft" :size="20"/>
        </div>
      </div>

      <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size="18" class="-spin-icon-load"></Icon>
          <div>地图加载中</div>
      </Spin>

      <Modal v-model="modalLevelManage" title="管理显示分级" footer-hide>
        <Row>
          <i-col span="24">
            <Tag color="gray"  v-for="level in levels" :key="level.id" closable size="medium" @on-close="handleDeleteLevel(level)">{{level.name}}</Tag>
          </i-col>
        </Row>

        <Row class="p-t-10">
          <i-col span="24">
            <Input size="small" v-model="newLevelName" placeholder="请输入新的分级名称..." style="width: 300px" />
            <Button size="small" :disabled="newLevelName.length===0" type="success" class="m-l-5" style="width: 80px" @click="handleAddLevel">新增分级</Button>
          </i-col>
        </Row>
    </Modal>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import { getStationSvg, getSvgLevel, getSvgElement, addSvgLevel, deleteSvgLevel, getSvgElementLevel, addSvgElementLevel } from '@/api/product/stationgraph'

export default {
  data () {
    return ({
      spinShow: false,
      modalLevelManage: false,
      clientHeight: 0,
      showRightBlock: true,
      svgInfo: {
        svg: '',
        floor: null,
        stationId: 0,
        stationName: '',
        positionName: '',
        position: null
      },
      svgTags: [],
      levels: [], // 显示分级清单
      chooseLevel: null,
      category: [],
      chooseCategory: '',
      social: ['facebook', 'github'],

      chkAllElement: [], // 全局选中的显示元素
      pathNodeList: [], // 存储全局的path元素

      newLevelName: ''
    })
  },
  created () {
    this.initPageData()
    this.clientHeight = document.documentElement.clientHeight - 5
  },
  methods: {
    initPageData () {
      this.spinShow = true
      // 获取站点svg条件字段封装
      const postData = {
        floor: -1,
        position: 2,
        stationId: 282
      }

      getStationSvg(postData).then(res => {
        this.svgInfo = res
        this.spinShow = false

        if (this.svgInfo.svg) {
          this.$nextTick(() => {
            const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
            // 设置svg呈现在画板上的尺寸
            cuurentSvgElement.setAttribute('width', '100%')
            cuurentSvgElement.setAttribute('height', '100%')

            svgPanZoom(cuurentSvgElement, {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              center: true
            }).zoom(1.3)

            this.pathNodeList = document.getElementsByTagName('path')
            this.pathNodeList.forEach(element => {
              if (element.hasAttribute('name')) {
                element.setAttribute('display', 'none')
              }
            })
          })
        }

        this.loadCategory()
      })
    },
    loadCategory () {
      this.category = []
      getSvgElement().then(res => {
        this.svgTags = res
        res.forEach(element => {
          if (!this.category.includes(element.category)) {
            this.category.push(element.category)
          }
        })
        this.chooseCategory = this.category[0]
        this.loadLevels()
      })
    },
    loadLevels () {
      getSvgLevel().then(res => {
        this.levels = res
        this.chooseLevel = (res.length > 0) ? res[0].id : null
        this.handleChangeLevel()
      })
    },
    formatCategoryName (name) {
      let formatName = ''

      switch (name) {
        case 'lightbox':
          formatName = '灯箱'
          break
        case 'normal':
          formatName = '常规'
          break
        case 'basic':
          formatName = '基础'
          break
        case 'stickers':
          formatName = '贴纸'
          break
        default:
          break
      }

      return formatName
    },
    chkAllElementChange (data) {
      this.pathNodeList.forEach(element => {
        if (element.hasAttribute('name')) {
          if (data.includes(element.getAttribute('name'))) {
            element.setAttribute('display', 'inline')
          } else {
            element.setAttribute('display', 'none')
          }
        }
      })
    },
    handleAddLevel () {
      addSvgLevel({ name: this.newLevelName }).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '新建显示分级成功！' })
          this.newLevelName = ''
          this.loadLevels()
        }
      })
    },
    handleDeleteLevel (level) {
      this.$Modal.confirm({
        title: '确认移除',
        content:
          '<p>确认要移除 <span class="text-orange">' + level.name + '</span>吗？ 此操作不可逆转，确认继续？</p>',
        onOk: () => {
          deleteSvgLevel({ levelId: level.id }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '移除显示分级成功！' })
              this.loadLevels()
            }
          })
        }
      })
    },
    handleChangeLevel () {
      this.chkAllElement = []
      getSvgElementLevel({ levelId: this.chooseLevel }).then(res => {
        res.forEach(element => {
          this.chkAllElement.push(this.svgTags.find(x => x.id === element.elementId).name)
        })

        this.chkAllElementChange(this.chkAllElement)
      })
    },
    handleSveLevelElement () {
      const elementIds = []
      this.chkAllElement.forEach(element => {
        elementIds.push(this.svgTags.find(x => x.name === element).id)
      })

      const postData = {
        levelId: this.chooseLevel,
        elementIds: JSON.stringify(elementIds)
      }

      addSvgElementLevel(postData).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '保存设置成功！' })
        }
      })
    }
  }
}
</script>
